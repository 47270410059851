import React, { useState } from 'react';
import './index.css';

function App() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="App">
      <img src="/front-inicial.png" alt="Logo" className="App-logo" />
      <button className="drawer-toggle" onClick={handleDrawerToggle}>
        <span className="menu-icon">&#9776;</span>
      </button>
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={handleDrawerToggle}>×</button>
        <ul>
          <li><a href="/">Página inicial</a></li>
          <li><a href="/login">Login</a></li>          
          <li><a href="/termos-de-uso">Termos de Uso</a></li>
        </ul>
      </div>
      <div className="app-container">
        <img src="/background.png" alt="Background" className="background-image" />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="pdf-content">
          <br></br>
          <br></br>
          <h1>POLÍTICAS DE PRIVACIDADE, TERMOS USO E CONDIÇÕES GERAIS DO APLICATIVO “RANGOO”</h1>
          <br></br>
          <p>De um lado, na qualidade de (i) <strong>USUÁRIO</strong> da plataforma e do aplicativo, já devidamente qualificado nos termos dos dados já inseridos no cadastro, doravante denominado neste instrumento como <strong>USUÁRIO</strong>.</p>

          <p>E do outro, na qualidade de desenvolvedora e titular de toda a propriedade intelectual, industrial e autoral relacionada à plataforma e ao aplicativo RANGOO, (ii) <strong>NUTRIASE TECNOLOGIA E SAÚDE LTDA</strong>, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o n. 54.377.535/0001-05, localizada na Rua Valdomiro Silveira, 162, São Paulo/SP, CEP: 02536-020, doravante denominada neste instrumento como <strong>RANGOO</strong>.</p>

          <p>As partes acima pactuam, de forma livre e voluntária, as condições relacionadas à <strong>“Política de Privacidade, Termos de Uso e Condições Gerais do Aplicativo RANGOO”</strong> (“Termo”), que reger-se-ão de acordo com as cláusulas e condições estabelecidas no presente Termo.</p>
          
          <br></br>
          <h2>I. DO OBJETO</h2>
          <br></br>

          <p>A plataforma visa propiciar o uso do aplicativo RANGOO e demais ativos de propriedade intelectual atrelados ao RANGOO, fornecendo ferramentas para auxiliar e dinamizar o dia a dia dos seus usuários com relação ao fornecimento de sugestões detalhadas de dietas diárias, que serão elaboradas com base nos dados e objetivos selecionados pelo USUÁRIO no aplicativo.</p>
          
          <br></br>
          <h2>II. DA CONCORDÂNCIA COM OS TERMOS DE USO</h2>
          <br></br>

          <p>O presente Termo estabelece obrigações contratadas de forma livre e espontânea, por tempo indeterminado, pelo USUÁRIO do aplicativo RANGOO.</p>

          <br></br>
          <h2>III. DO ACESSO À PLATAFORMA PELO USUÁRIO</h2>
          <br></br>

          <p>O acesso do USUÁRIO à plataforma será por meio da criação de cadastro a ser feito pelo USUÁRIO no aplicativo da RANGOO. O cadastro do USUÁRIO é pessoal, exclusivo e intransferível. Para acesso ao cadastro, o USUÁRIO deverá criar uma senha confidencial, sendo responsabilidade exclusiva do USUÁRIO a inserção e atualização dos seus dados pessoais na plataforma, bem como a utilização, guarda, manutenção da confidencialidade e escolha da senha de acesso.</p>

          <br></br>
          <h2>IV. DOS DADOS PESSOAIS SOLICITADOS NO CADASTRO E A FINALIDADE NA UTILIZAÇÃO DOS DADOS</h2>
          <br></br>
          <p>Para se cadastrar o USUÁRIO deverá indicar os seguintes dados pessoais, os quais serão utilizados pela RANGOO com as seguintes finalidades:</p>

          <ul>
            <li><strong>Login:</strong> E-mail e senha (Confirmar identidade do usuário e localizar seus dados).</li>
            <li><strong>Cadastro:</strong> Nome completo, data de nascimento, CPF, sexo biológico, e-mail, celular, eventual alergia/intolerância/restrição alimentar (Esses dados serão utilizados nos cálculos de IMC e consumo calórico do usuário).</li>
          </ul>

          <br></br>
          <h2>V. DO SUPORTE</h2>
          <br></br>

          <p>A RANGOO dispõe do seguinte canal oficial de suporte do USUÁRIO, o e-mail: suporte@rangoo.app.br.</p>

          <br></br>
          <h2>VI. DO CANCELAMENTO</h2>
          <br></br>

          <p>O USUÁRIO poderá cancelar a qualquer tempo o seu cadastro feito junto à plataforma da RANGOO, basta que clicar no botão “excluir conta”, que está localizado na própria sessão de perfil do USUÁRIO.</p>

          <br></br>
          <h2>VII. DAS RESPONSABILIDADES DO USUÁRIO</h2>
          <br></br>

          <p>Ao acessar a plataforma, o USUÁRIO declara que irá respeitar todos os direitos de propriedade intelectual, autoral e quaisquer outros que estiverem disponíveis na plataforma da RANGOO, como logotipos, marcas, nomes comerciais, layouts, gráficos, design, imagens, entre outros.</p>

          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
}

export default App;
