import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import users from '../server_backend/users.json';
import aws4 from 'aws4';
import './index.css';

function SearchOrder() {
  const [orderId, setOrderId] = useState('');
  const [restaurantId, setRestaurantId] = useState('');
  const [orderData, setOrderData] = useState(null);
  const [ordersData, setOrdersData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [userType, setUserType] = useState('');
  const [loggedInUser, setLoggedInUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const user = users.find(u => u.email === userEmail);
    if (user) {
      setUserType(user.type);
      setLoggedInUser(user);
      if (user.type !== 'admin') {
        setRestaurantId(user.restaurantId);
      }
    }
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userEmail');
    navigate('/login');
  };

  const signRequest = (opts) => {
    aws4.sign(opts, {
      accessKeyId: 'AKIA6ODUZGVV4F6BAABT',
      secretAccessKey: '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'
    });

    delete opts.headers['Host'];
    delete opts.headers['Content-Length'];

    return opts;
  };

  const handleSearchByOrderId = async (event) => {
    event.preventDefault();
    if (!orderId) {
      alert('Order ID é obrigatório.');
      return;
    }

    const opts = {
      host: 'emh7viuil4.execute-api.us-east-2.amazonaws.com',
      path: `/hml/v1/Orders/GetOrderById?Id=${orderId}`,
      method: 'GET',
      service: 'execute-api',
      region: 'us-east-2',
      headers: {
        'Content-Type': 'application/json',
      }
    };

    const signedOpts = signRequest(opts);

    try {
      const response = await fetch(`https://${signedOpts.host}${signedOpts.path}`, {
        method: signedOpts.method,
        headers: signedOpts.headers,
      });
      const data = await response.json();
      setOrderData(data);
      setOrdersData([]);
    } catch (error) {
      console.error('Error fetching order data:', error);
    }
  };

  const handleSearchByRestaurantId = async (event) => {
    event.preventDefault();
    if (!restaurantId) {
      alert('Restaurant ID é obrigatório.');
      return;
    }

    const opts = {
      host: 'emh7viuil4.execute-api.us-east-2.amazonaws.com',
      path: `/hml/v1/Orders/GetAllOrderByRestaurantId?RestaurantId=${restaurantId}`,
      method: 'GET',
      service: 'execute-api',
      region: 'us-east-2',
      headers: {
        'Content-Type': 'application/json',
      }
    };

    const signedOpts = signRequest(opts);

    try {
      const response = await fetch(`https://${signedOpts.host}${signedOpts.path}`, {
        method: signedOpts.method,
        headers: signedOpts.headers,
      });
      const data = await response.json();
      setOrdersData(data);
      setOrderData(null);
    } catch (error) {
      console.error('Error fetching restaurant orders:', error);
    }
  };

  const renderOrderDetails = (order) => (
    <div className="order-details">
      <h2>Detalhes do Pedido</h2>
      <p><strong>Order ID:</strong> {order.id}</p>
      <p><strong>ID do Cliente:</strong> {order.clientId}</p>
      <p><strong>Nome do Cliente:</strong> {order.clientName}</p>
      <p><strong>Número do Pedido:</strong> {order.orderNumber}</p>
      <p><strong>Data do Pedido:</strong> {new Date(order.orderDate).toLocaleString()}</p>
      <p><strong>Status do Pagamento:</strong> {translatePaymentStatus(order.paymentStatus)}</p>
      <p><strong>Status do Pedido:</strong> {translateOrderStatus(order.orderStatus)}</p>
      <p><strong>Subtotal:</strong> R$ {order.subtotalPrice?.toFixed(2).replace('.', ',') ?? 'N/A'}</p>
      <p><strong>Taxa de Entrega:</strong> R$ {order.deliveryFee?.toFixed(2).replace('.', ',') ?? 'N/A'}</p>
      <p><strong>Desconto:</strong> R$ {order.discountValue?.toFixed(2).replace('.', ',') ?? 'N/A'}</p>
      <p><strong>Total:</strong> R$ {order.totalPrice?.toFixed(2).replace('.', ',') ?? 'N/A'}</p>
      <h3>Itens do Pedido</h3>
      {order.meals && order.meals.length > 0 ? (
        order.meals.map((meal, index) => (
          <div key={index} className="meal">
            <p><strong>Nome:</strong> {meal.name}</p>
            <p><strong>Preço:</strong> R$ {meal.price.toFixed(2).replace('.', ',')}</p>
            <p><strong>Quantidade:</strong> {meal.quantity}</p>
            <p><strong>Ingredientes:</strong></p>
            <ul>
              {meal.ingredients.map((ingredient, idx) => (
                <li key={idx}>
                  {ingredient.food}: {parseFloat(ingredient.amount).toFixed(0)} {translateUnit(ingredient.unit)}
                </li>
              ))}
            </ul>
          </div>
        ))
      ) : (
        <p>Não há itens no pedido.</p>
      )}
      <h3>Endereço de Entrega</h3>
      {order.deliveryAddress && order.deliveryAddress.length > 0 ? (
        order.deliveryAddress.map((address, index) => (
          <div key={index} className="address">
            <p><strong>Rótulo:</strong> {address.Label}</p>
            <p><strong>Endereço:</strong> {address.Address}</p>
            <p><strong>Número:</strong> {address.Number}</p>
            <p><strong>Cidade:</strong> {address.City}</p>
            <p><strong>Estado:</strong> {address.State}</p>
            <p><strong>CEP:</strong> {address.ZipCode}</p>
            <p><strong>Complemento:</strong> {address.Complement}</p>
          </div>
        ))
      ) : (
        <p>Não há endereço de entrega registrado.</p>
      )}
    </div>
  );

  const renderOrdersList = (orders) => (
    <div className="orders-list">
      <h2>Pedidos do Restaurante</h2>
      {orders.map((order, index) => (
        <div key={index} className="order-summary">
          <p><strong>Order ID:</strong> {order.id}</p>
          <p><strong>Nome do Cliente:</strong> {order.clientName}</p>
          <p><strong>Número do Pedido:</strong> {order.orderNumber}</p>
          <p><strong>Status do Pagamento:</strong> {translatePaymentStatus(order.paymentStatus)}</p>
          <p><strong>Status do Pedido:</strong> {translateOrderStatus(order.orderStatus)}</p>
        </div>
      ))}
    </div>
  );

  const translatePaymentStatus = (status) => {
    switch (status) {
      case 0: return 'Pendente';
      case 1: return 'Pago';
      case 2: return 'Cancelado';
      case 3: return 'Reembolsado';
      default: return 'Desconhecido';
    }
  };

  const translateOrderStatus = (status) => {
    switch (status) {
      case 0: return 'Cancelado';
      case 1: return 'Completo';
      case 2: return 'Em Andamento';
      default: return 'Desconhecido';
    }
  };

  const translateUnit = (unit) => {
    switch (unit) {
      case 0: return 'gramas';
      case 1: return 'ml';
      default: return '';
    }
  };

  return (
    <div className="App">
      <img src="/front-inicial.png" alt="Logo" className="App-logo" />
      {!isDrawerOpen && (
        <button className="drawer-toggle" onClick={handleDrawerToggle}>
          <span className="menu-icon">&#9776;</span>
        </button>
      )}
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={handleDrawerToggle}>×</button>
        <ul>
          <li><a href="/auth-inicial">Home</a></li>
          <li><a href="/update-order">Atualizar Status</a></li>          
          <li><a href="/search-order">Informações de Pedidos</a></li>
          <li><a href="/" onClick={handleLogout}>Logout</a></li>
        </ul>
      </div>
      <div className="app-container">
        <img src="/background.png" alt="Background" className="background-image" />
        <div className="SearchOrder-box">
          <h1>Buscar Pedido</h1>
          {userType === 'admin' && (
            <form onSubmit={handleSearchByRestaurantId}>
              <div className="form-group">
                <label>Selecione o Restaurante:</label>
                <select
                  value={restaurantId}
                  onChange={(e) => setRestaurantId(e.target.value)}
                >
                  <option value="">Selecione...</option>
                  <option value="4349fb4e-7cae-4f03-889c-759f74359a16">Saudável Refeições Fit</option>
                  <option value="64d6e447-d6ae-4fe7-885e-a6c225f15773">Zolé Cozinha</option>
                </select>
              </div>
              <button type="submit" className="search-button">Buscar todos os pedidos</button>
            </form>
          )}
          {userType !== 'admin' && (
            <form onSubmit={handleSearchByRestaurantId}>
              <button type="submit" className="search-button">Buscar todos os pedidos</button>
            </form>
          )}
          <form onSubmit={handleSearchByOrderId}>
            <div className="form-group">
              <br></br>
              <label>Order ID:</label>
              <input
                type="text"
                value={orderId}
                placeholder='Insira a Order ID'
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="search-button">Buscar por Order ID</button>
          </form>
          {orderData && renderOrderDetails(orderData)}
          {ordersData.length > 0 && renderOrdersList(ordersData)}
        </div>
      </div>
    </div>
  );
}

export default SearchOrder;
